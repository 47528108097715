<template>
<div>
    <div v-if="loading ">
        <div class="text-center p-2">
            <div class="widget-loading"></div>
        </div>
    </div>
    <div v-if="false" class="list-widget list-dash-items list-widget-structure">
        <div class="tgju-widget light components" style="padding: 5px">
            <ul style="max-height: 200px;overflow: auto;">
                <li v-for="item in category_list" :key="item.id">
                    <slot v-if="!isInHiddenItem(item.id)">
                        <router-link :to="{ name: 'MarketList', params: { market_id: item.id, child_category_list: category_list },}" class="l-item-btn-color-blue">
                            {{ item.label }} - <i :class="'uil ' + (item.type == 'folder' ? 'uil-folder' : 'uil-link-alt') "></i>
                        </router-link>
                    </slot>
                </li>
            </ul>
        </div>
    </div>
    <slot v-if="market_values">
        <div  :class="'market-main-item-row r-'+market_value.dt+''"  v-for="market_value in market_values.data" :key="market_value.id">
                <div class="market-main-item-col item-col-right">
                    <router-link :to="{ name: 'MarketProfile', params: { name: market_value.item_id },}">
                        <div class="market-main-item-title">
                            <h2 v-if="market_value.title">
                                {{ market_value.title }}
                            </h2>
                            <h2 v-else-if="market_value.title_second">
                                {{ market_value.title_second }}
                            </h2>
                            <h2 v-else-if="market_value.slug">
                                {{ market_value.slug }}
                            </h2>
                            <h2 v-else> {{ market_value.key }}</h2>
                            <!-- <span>بازار طلا</span> -->
                        </div>
                    </router-link>
                    <div class="market-main-item-time">
                        <i class="uil uil-clock"></i>
                        <span>{{ market_value.t }}</span>
                    </div>
                </div>
                <div class="market-main-item-col item-col-left">
                    <div class="market-main-item-price">
                        {{ formatPrice(market_value.p) }}
                    </div>
                    <div :class="'market-main-item-change'+' '+ market_value.dt">
                        {{ formatPrice(market_value.d) }} ({{ market_value.dp }} %)
                    </div>
                </div>
                <div class="add-to-watchlist" @click.prevent="openMarketAddToWatchListModal(market_value.id)" v-tooltip="'افزودن به واچ لیست'">
                    <i class="uil uil-file-plus-alt"></i>
                </div>
        </div>

        <infinite-loading @infinite="infiniteHandler">
            <div slot="spinner">
                <div class="widget-loading"></div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </slot>
    <slot v-if="stock_values">
        <div  class="market-main-item-row"  v-for="(stock_value, index) in stock_values.data" :key="index">
            <!-- <router-link :to="{ name: 'StockProfileOverview', params: { type: stock_value.info.market,  name: stock_value.info.symbol},}">
                <div class="market-main-item-col item-col-right">
                    {{ stock_value.info.title }}
                </div>
                <div class="market-main-item-col item-col-left">
                </div>
            </router-link> -->
            <div class="market-main-item-col item-col-right">
                <router-link :to="{ name: 'StockProfileOverview', params: { type: stock_value.info.market,  name: stock_value.info.symbol},}">
                    <div class="market-main-item-title">
                        <h2 v-if="stock_value.info.title">
                            {{ stock_value.info.title }}
                        </h2>
                        <h2 v-else-if="stock_value.info.symbol">
                            {{ stock_value.info.symbol }}
                        </h2>
                        <h2 v-else> {{ market_value.key }}</h2>
                        <!-- <span>بازار طلا</span> -->
                    </div>
                </router-link>
                <div class="market-main-item-time">
                    <i class="uil uil-clock"></i>
                    <span>{{ stock_value.info.market_fa }}</span>
                </div>
            </div>
            <div class="market-main-item-col item-col-left">
                <div class="market-main-item-price">
                    {{ stock_value.info.last_trade.PDrCotVal }}   
                </div>
                <span data-col="info.last_trade.last_change_percentage" :data-val="stock_value.info.last_trade.last_change_percentage">
                    <span class="change change-down change-percentage">{{ stock_value.info.last_trade.last_change_percentage }}</span>
                </span>
                <!-- <div :class="'market-main-item-change'+' '+ market_value.dt">
                    {{ formatPrice(market_value.d) }} ({{ market_value.dp }} %)
                </div> -->
            </div>
        </div>
    </slot>
</div>
</template>

<style lang="scss" scoped>
    .show {
        position: absolute;
        background-color: #f1f1f1;
        overflow: auto;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        z-index: 1;
        padding: 2px;
    }

    thead {
        display: table-header-group;
        vertical-align: middle;
        border-color: inherit;
    }

    .high.dir,
    .low.dir {
        max-height: 25px;
        margin: 10px;
    }
</style>

<script>
// این کامپوننت دارای اجزای بازار داینامیک می باشد
import InfiniteLoading from "vue-infinite-loading";
import Centrifuge from "centrifuge";
import moment from "moment-jalaali";
import Vue from "vue";

export default {
    name: "MarketDynamicComponent",
    props: ['data', 'parent_category_list', 'is_home'],
    components: {
        InfiniteLoading,
    },
    data: function () {
        return {
            market_values: [],
            stock_values: [],
            market_id: 0,
            page: 2,
            category_list: [],
            loading: false,
            hidden_item: []
            // hidden_item: ["stocks-stock", 87656, 28046, "stocks-bond", "stocks-index", 28221, "te-indicators-forecast", "te-indicators", "te-countries-forecast", "te-countries", "economics-indicators", "economics-countries", "stocks-future"]
        };
    },
    mounted() {
        if (this.$route.params.market_id == 'all') {
            this.getStaticMarket();
        } else {
            this.getCategoryList(this.$route.params.market_id);
        }

        Vue.use(InfiniteLoading, {
            slots: {
                noMore: "محتوا کاملاً بارگیری شدند", // you can pass a string value
            },
        });
    },
    methods: {
        // این متد ارتباط با سوکت را برقرار میکند و سپس داده های موجود در صفحه را آپدیت میکند
        socketMarket() {
            var self = this;
            var centrifuge = new Centrifuge("wss://stream.tgju.org/connection/websocket");

            centrifuge.subscribe("tgju:stream", function (ctx) {
                for (var i in ctx.data) {
                    var item = ctx.data[i].split("|");
                    if (item[0] === 'market') {
                        for (const [index, market_item] of Object.entries(self.market_values.data)) {
                            if (market_item.item_id == item[1]) {
                                self.market_values.data[index].d = item[10];
                                self.market_values.data[index].dp = item[11];
                                self.market_values.data[index].dt = item[12];
                                self.market_values.data[index].h = item[7];
                                self.market_values.data[index].l = item[8];
                                self.market_values.data[index].p = item[6];
                                self.market_values.data[index].t = item[13];
                            }
                        }
                    }
                }
            });
            centrifuge.connect();
        },
        getStaticMarket() {
            this.loading = false;
            this.category_list = this.parent_category_list;
            this.$store.commit('setMarketlayer', 2);
        },
        // این متد با توجه به آرگومان ورود لیست بازار ها و یا دسته بندی ها را برمیگرداند
        getCategoryList(select_opt) {
            this.$helpers
                .makeRequest("GET", "/market/category" + (select_opt == 'all' ? '' : '/' + select_opt))
                .then((api_response) => {
                    var hasFolder = api_response.data.response.detail.items.map(item => {
                        return item.type
                    }).includes('folder')

                    if (hasFolder) {
                        this.loading = false;
                        this.category_list = api_response.data.response.detail.items;
                        this.category_list.unshift({id: select_opt, label: 'نمای کلی', type: 'folder'});
                        this.$store.commit('setMarketLvl3', this.category_list);
                        this.$store.commit('setMarketlayer', 3);
                    } else {
                        this.getMarketValue(select_opt);
                        this.$store.commit('setMarketlayer', 4);
                        this.$root.$emit('hide_static_market', true);
                        // this.socketMarket();
                    }
                });
        },
        // این متد نرخ ها و شاخص های یک دسته بندی را با توجه به آرگومان ورود بر میگرداند
        getMarketValue(id) {
            this.loading = true;
            this.$helpers
                .makeRequest("GET", "/market/markets-by-category/" + id)
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.market_values = api_response.data.response.detail.indicators;
                        if (!api_response.data.response.detail.indicators) {
                            this.stock_values = api_response.data.response.detail.instruments;
                        }
                        this.loading = false;
                    }
                });
        },
        // این متد برای لود بینهایت صفحه بندی به صورت اسکرول برای شاخص ها استفاده میشود
        infiniteHandler($state) {
            this.$helpers
                .makeRequest("GET", "/market/markets-by-category/" + this.$route.params.market_id, {
                    page: this.page,
                })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.indicators.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.indicators.data)) {
                                this.market_values.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                });
        },
        // این متد برای باز کردن مودال افزودن نماد به واچ لیست و هندل کال بک آن مورد استفاده قرار میگیرد
        openMarketAddToWatchListModal(symbol) {
            let callbackEvent = this.$router.currentRoute.name + '_MarketAddToWatchList_' + new Date().getTime();

            let modal_data = {
                modal: 'general_modal',
                callbackEvent,
                component: 'MarketAddToWatchList.vue',
                config: {
                    title: 'افزودن نماد به واچ لیست',
                    smallModal: true,
                    data: {
                        symbol: symbol
                    },
                }
            };

            this.$root.$emit('open_modal', modal_data);

            this.$root.$on(callbackEvent, (response) => {
                if (response.action == 'addMarketToWatchList') {
                    this.$helpers.makeRequest('POST', '/watchlist/add-remove-market-to-watch-list', {
                        watch_list_id: response.data.category_id,
                        market_symbol: symbol
                    }).then(api_response => {
                        Vue.prototype.$toast.success({
                            title: 'موفق',
                            message: api_response.data.response.status == 'add-success' ? 'با موفقیت افزوده شد' : 'با موفقیت حذف شد',
                        });
                    });
                }
            });
        },
        // این متد جهت نمایش مناسب زمان مورد استفاده قرار میگیرد
        toRelative(date) {
            return moment(date).fromNow();
        },
        // این متد جهت نمایش بهتر نرخ ها و قیمت ها می باشد // جدا کننده قیمت با کاما
        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        isInHiddenItem(value) {
            return this.hidden_item.includes(value);
        },
        // این متد برای هندل کردن منوی دراپ دان لیست می باشد
        dropDown(name) {
            var el = document.getElementById("myDropdown-" + name);

            if (el.classList.contains('d-none')) {
                el.classList.remove('d-none');
                el.classList.toggle('show');
                return 0;
            }

            if (el.classList.contains('show')) {
                el.classList.remove('show');
                el.classList.toggle('d-none');
                return 0;
            }
        },
    },
};
</script>
